import React from 'react';
import logo from '../assets/Acadian Logo Colored - Stacked.png';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { FaComputer } from 'react-icons/fa6';

const AmyContact = () => {
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center bg-blue-900 text-white gap-6'>
      <div className='flex items-center justify-center w-[250px] h-[250px] overflow-hidden bg-white rounded-full'>
        <img src={logo} alt='Ryan Avatar' className='w-[60%]' />
      </div>
      <h4 className='text-5xl'>Amy Choate</h4>
      <h5 className='text-2xl -mt-2'>Sales</h5>
      <a href='tel: +1-832-588-8559' className=''>
        <button className='flex items-center justify-center gap-2 px-6 py-4 bg-green-600 rounded-3xl'>
          <FaPhoneAlt className='text-lg' />
          CALL ME
        </button>
      </a>
      <a href='mailto: amy@acadianpos.com' className=''>
        <button className='flex items-center justify-center gap-2 px-6 py-4 bg-red rounded-3xl'>
          <FaEnvelope className='text-lg' />
          EMAIL ME
        </button>
      </a>
      <a href='/' target='_blank'>
        <button className='flex items-center justify-center gap-2 px-6 py-4 bg-orange-500 rounded-3xl'>
          <FaComputer className='text-lg' />
          OUR WEBSITE
        </button>
      </a>
    </div>
  );
};

export default AmyContact;
