import logo2 from '../assets/AcadianLogoBlue.svg';
import { FaCalendarAlt } from 'react-icons/fa';

const Header = ({ showForm }) => {
  return (
    <header className='py-4 z-30 fixed w-full top-0 bg-white '>
      <div className='container flex justify-center md:justify-between items-center mx-auto px-8 md:px-12 lg:px-16 w-full'>
        <a className='' href='#home'>
          <img src={logo2} alt='Acadian POS' className='w-52 md:w-72 md:pr-8' />
        </a>
        <div className='hidden md:flex space-x-6 lg:space-x-12 items-center whitespace-nowrap'>
          <a href='#home'>Home</a>
          <a href='#about'>About Us</a>
          <a href='#features'>Product Features</a>
          <a href='#featuredIntegration'>Featured Integration</a>
          <a href='#contact'>Contact Us</a>
          <a
            href='https://meet.brevo.com/ryan-leger'
            className='flex justify-center items-center  gap-2 bg-primaryDarker px-6 py-4 rounded-lg text-white'
          >
            <FaCalendarAlt />
            <span>Schedule a Meeting</span>
          </a>
          {/*<a href='#demo'><button className='px-4 py-3 bg-primary text-white font-bold rounded-3xl hover:bg-primaryLighter ' onClick={() => showForm(true)}>Schedule a Demo</button></a>*/}
        </div>
      </div>
    </header>
  );
};

export default Header;
